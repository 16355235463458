<template>
  <div>
    <v-form
      ref="explorerImport"
      action="?f=administration&f2=explorers"
      method="post"
    >
      <v-row style="max-height: 40px; position: relative; bottom: 50px">
        <v-col class="ml-1 mt-7" style="max-width: 1050px">
          <radio
            :fieldAttributes="{
              option: fieldAttributes.importOptions,
            }"
            :fieldAttrInput="{ class: 'required' }"
            v-model="selectedType"
            :field="field+'_radio'"
            :result="result"
          ></radio>
        </v-col>
      </v-row>
      <v-row style="max-height: 100px">
        <v-col style="max-width: 98.3%" class="ml-1">
          <fileReader
            v-model="file"
            :field="field"
            :fieldAttributes="fieldAttributes"
            :fieldAttrInput="fieldAttributes.fieldAttrInput"
            @load="registerUpload()"
            :templateContent="result"
            ref="csvUpload"
          ></fileReader>
        </v-col>
      </v-row>
      <v-row class="ml-n1" style="position: relative; bottom: 18px">
        <v-col class="ml-1" style="max-width: 150px">
          <primaryButton
            label="Import"
            @click="uploadCSV"
            icon="mdi-database-import"
            color="save"
            :disabled="disabled"
            type="button"
          >
          </primaryButton>
        </v-col>
        <v-col class="ml-1">
          <primaryButton
            label="Close"
            @click="closedialog()"
            icon="mdi-close"
            color="secondary"
            type="button"
            :attributesArray="{ id: 'closeExplorerImport' }"
          >
          </primaryButton>
        </v-col>
      </v-row>
      <!--    <v-row>
                        <v-col style="max-height: 200px;overflow-y: scroll;max-width:1000px">

                        </v-col>
                    </v-row>-->
      <div style="max-height: 200px; overflow-y: auto; max-width: 99%">
        <v-data-table
          dense
          :headers="headers"
          :items="dataItems"
          class="elevation-1"
          :hide-default-footer="true"
          fixed-header
        ></v-data-table>
      </div>
    </v-form>
  </div>
</template>
<script>
import fileReader from "@/commonComponents/fileReader.vue";
import radio from "@/commonComponents/radio.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
import { refreshBody } from "@/js/helper.js";
import requests from '@/js/requests';

export default {
  props: ["fieldAttributes", "field", "result"],
  data() {
    return {
      file: null,
      type: "",
      fileContent: "",
      disabled: true,
      headers: [
        { text: "Date", value: "n_date", width: "165px" },
        { text: "Type", value: "c_type", width: "185px" },
        { text: "File Name", value: "c_objectStoreLink", width: "165px" },
        { text: "Status", value: "n_status", width: "100px" },
        { text: "Message", value: "c_msg", width: "150px" },
      ],
      dataItems: this.result.json.importedFileList,
      fileType: "",
      uploaded: false,
    };
  },
  components: {
    fileReader,
    radio,
    primaryButton,
  },
  computed: {
    selectedType: {
      get() {
        return this.value;
      },
      set(newVal) {
        if (this.$refs.explorerImport.validate()) {
          this.disabled = false;
        }
        this.fileType = newVal;
        this.$emit("input", newVal);
      },
    },
  },
  created() {
    // get list of uploaded files
    this.timeout(); // keep update the table!
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
    if (this.uploaded) {
      //if uploaded then refresh body when closed the dialog
      refreshBody(this, "?f=administration&f2=explorers");
    }
    //other possible way to filter once again! instead of refresh!
    // this.$root.$refs.explorerTagFilter.applyFilter('showAll');
  },
  methods: {
    timeout() {
      var caller = this;
      this.timer = setTimeout(function () {
        caller.getUploadedFileList();
        caller.timeout();
      }, 15000);
    },

    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    closedialog() {
      this.$store.commit("closeDialog", this.result.dialogRef);
    },
    registerUpload() {
      if (this.$refs.explorerImport.validate()) {
        this.disabled = false;
      }
      this.$emit("file-upload", this.field, this.file);
    },
    uploadCSV() {
      let caller = this;
      if (
        this.$refs.explorerImport.validate() &&
        this.fileType != "" &&
        this.file != ""
      ) {
        caller.disabled = true;
        caller.uploaded = true;
        let formData = new FormData();
        formData.append("fileName", this.file);
        formData.append("type", this.fileType);
        this.axios
          .post(
            "serve.php?f=administration&f2=explorers&function=uploadExploreList",
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          )
          .then(function (response) {
            caller.$notify({
              group: "foo",
              text: "Successfully Uploaded!",
              duration: 1000,
              type: "success",
              speed: 600,
            });
            caller.dataItems = response.data.result.json.importedFileList;
          })
          .catch(function () {
            console.log("update failed!!");
          });
      } else {
        if (typeof this.file == "undefined") {
          caller.$notify({
            group: "foo",
            text: "Please select a CSV file!!",
            duration: 1000,
            type: "error",
            speed: 600,
          });
        } else {
          caller.$notify({
            group: "foo",
            text: "Please select type of file!",
            duration: 1000,
            type: "error",
            speed: 600,
          });
        }
      }
    },
    getUploadedFileList() {
      let options = {
        function: "getUploadedFiles",
      };
      let caller = this;
      requests.frameworkAxiosRequest({
        method: 'post',
        url: 'serve.php?f=administration&f2=explorers',
        data: options,
      })
          .then(function (response) {
          caller.dataItems = response.data.result.json.importedFileList;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>